body {
  height: 100%;
}

html {
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
  background-color: #182026;
}

.app {
  flex-grow: 3;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.main-nav-header-form {
  width: 100%;
  max-width: 360px;
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.route-container {
  height: 100%;
}

.sign-in {
}

.recommendation-item {
  margin: 0.5em;
  text-align: center;
}

.recommendations-container {
  max-width: 20%;

  display: flex;
  flex-direction: column-reverse;
}

.input-box-container {
  margin-top: 0.5em;
}

.chatbox-input {
  margin-bottom: 0.5em;
}

.chatbox-conditions-picker {
  margin-bottom: 0.5em;
}

.conditions-popover {
}

.conditions {
  width: 100%;
}
.conditions-target {
  width: 100%;
}

.full-width {
  width: 100%;
}

.conditions-item {
  width: 100w;
}

div.conditions-popover > div > div > ul {
  overflow-y: scroll;
  max-height: 300px;
}

.chatbox-container {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.chatbox-item {
  margin: 0.5em;
}

.item-user {
  margin-left: 5em;
  text-align: right;
}

.item-doctor {
  margin-right: 5em;
}

.chatbox-conversation {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  flex-shrink: 0;
  justify-self: flex-end;
}

.accounts-container {
  width: 100%;
  display: flex;
}

.account-card {
  margin: 0.5em;
  border-left: 6px red solid;
  border-left-color: blue;
}

.respond-page-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.respond-box {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}

.main-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  overflow: hidden;
}

.table-content {
  width: 100%;
}
.scrollable {
  overflow-y: scroll !important;
}

.scrollable::-webkit-scrollbar {
  width: 0 !important;
}

.form-item {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.main-nav-header-logo {
  text-align: center;
}
.main-nav-header-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-nav {
  overflow-y: scroll;
  padding-bottom: 30px;
  width: 100%;
  background-color: #30404d;
}
.main-body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.main-nav-wrapper {
  position: relative;
}

/* Speciality Selector  */

.dd-wrapper {
  user-select: none;
  position: relative;
}

.dd-header {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dd-header-title {
  font-weight: 300;
  font-size: 17px;
  margin: 0px 6px;
}

.angle-down {
  color: black;
  margin-right: 20px;
}

.dd-list {
  z-index: 10;
  position: absolute;
  width: 100%;
  border-top: none;
  background-color: rgb(29, 105, 154);
  font-weight: 700;
  max-height: 215px;
  padding: 4px;
  padding-bottom: 0px;
  border-radius: 3px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.dd-list-item {
  font-weight: 300;
  width: 100%;
  padding: 5px 6px;
  margin: 1px 0px;
  border-radius: 3px;
  line-height: 1.6rem;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  transition: 0.3s;
}

.dd-list-selected-item {
  background-color: white;
  color: rgb(37, 127, 185);
  cursor: default;
}

.dd-list-item:hover {
  background-color: white;
  color: rgb(37, 127, 185);
}

.bp3-tab {
  outline: none;
}

.labTest {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* justify-content: space-between; */
}

.labTestCategory {
  font-weight: bold;
}

.labTestCategory:hover {
  color: rgba(255, 255, 255, 0.5);
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tick-label {
  fill: #f5f8fa !important;
}

.tick-label text {
  fill: #f5f8fa !important;
}

.tooltip {
  pointer-events: none;
}

.bp3-omnibar {
  left: calc(50% - 500px) !important;
  width: 1000px !important;
}
